/*
 * Form interaction
 */
document.addEventListener('DOMContentLoaded', (event) => {
  const submitBtn = document.querySelector('#submit-btn');
  const name = document.querySelector('input[name="name"]');
  const email = document.querySelector('input[name="email"');
  const content = document.querySelector('textarea');

  // Input validation
  const requireds = document.querySelectorAll(
      'input[required], textarea[required]'
  );

  requireds.forEach((required, index) => {
    required.addEventListener('keyup', (event) => {
      let filled = true;
      requireds.forEach((v, i) => {
        if (!v.value) {
          filled = false;
        }
      });
      if (filled) {
        submitBtn.style.cursor = 'pointer';
        submitBtn.style.color = '#fff';
        // submitBtn.style.backgroundColor = '#7AA665';
        // submitBtn.style.backgroundColor = '#A9BF99';
        submitBtn.style.backgroundColor = '#A4CF72';
      } else {
        submitBtn.style.cursor = 'default';
        submitBtn.style.color = '#333';
        submitBtn.style.backgroundColor = '#ddd';
      }
    });
  });

  // Submit process
  submitBtn.addEventListener('click', (event) => {
    let filled = true;
    requireds.forEach((v, i) => {
      if (!v.value) {
        filled = false;
        v.nextElementSibling.style.display = 'block';
      } else {
        v.nextElementSibling.style.display = 'none';
      }
    });
    if (filled) {
      document.querySelector('#submit-form').submit();
    } else {
      return false;
    }
  });
});
